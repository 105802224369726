import React, { useEffect, useRef, useState } from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { injectIntl, FormattedMessage } from 'react-intl';
import { bindActionCreators } from 'redux';
import { createStructuredSelector } from 'reselect';
import { useVT } from 'virtualizedtableforantd4';
import { fromJS } from 'immutable';
import { Table, Button, Dropdown, Menu } from 'antd';

import UsersVirtualized from './UsersVirtualized';
import DriverScoreCard from '../../containers/ReportComportement/styles';
import OutlineButton from '../OutlineButton';
import colors from '../../styles/colors';
import dateFns from 'date-fns';

export const UserListComponent = (props) => {
    const [users, setUsers] = useState([]);
    const [vt] = useVT(() => ({ debug: true, scroll: { y: 500 } }), []);

    useEffect(
        () => {
            if (props.userList && props.userList.length > 0) {
                setUsers(props.userList);
            }
        },
        [props.userList]
    );

    const handleEditUser = (user) => {
        props.onEditUser && props.onEditUser(user.id);
    };

    const handleDeleteUser = (user) => {
        props.onDeleteUser && props.onDeleteUser(user.id);
    };

    const userOptions = (user) => (
        <Dropdown
            overlay={
                <Menu>
                    <Menu.Item onClick={() => handleEditUser(user)}>Edit</Menu.Item>
                    <Menu.Item onClick={() => handleDeleteUser(user)}>Delete</Menu.Item>
                </Menu>
            }
        >
            <Button>...</Button>
        </Dropdown>
    );

    const columns = [
        {
            name: 'firstName',
            header: props.intl.formatMessage({
                id: 'components.DashboardData.Header.Name',
            }),
            width: '35%',
            cell: (value) => {
                return <div>{`${value.firstName} ${value.lastName}`}</div>;
            },
        },

        {
            name: 'lastLogin',
            header: props.intl.formatMessage({
                id: 'components.DashboardData.Header.LastLogin',
            }),
            width: '30%',
            cell: (record) => {
                if (record.lastLogin) {
                    return dateFns.format(record.lastLogin, 'YYYY/MM/DD - h:mm A');
                }
            },
        },

        {
            name: 'groups',
            header: props.intl.formatMessage({
                id: 'components.DashboardData.Header.UserGroups',
            }),
            width: '28%',
            cell: (value, index, text) => {
                if (value.groups && value.groups.length > 0) {
                    return value.groups.map((data) => {
                        return <span key={data.id || data.name}>{`${data.name} `}</span>;
                    });
                }
            },
        },

        {
            name: '',
            cell: (record) => userOptions(record),
        },
    ];

    return (
        <DriverScoreCard.TableVirtual style={{ marginBottom: '60px', display: 'flex' }}>
            <UsersVirtualized data={users ? users : []} columns={columns} />
        </DriverScoreCard.TableVirtual>
    );
};

const mapStateToProps = createStructuredSelector({});

const mapDispatchToProps = (dispatch) => bindActionCreators({}, dispatch);

export default withRouter(
    connect(
        mapStateToProps,
        mapDispatchToProps
    )(injectIntl(UserListComponent))
);
