// @flow

import React, { type Node } from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { injectIntl, FormattedMessage } from 'react-intl';
import { bindActionCreators } from 'redux';
import { createStructuredSelector } from 'reselect';
import moment from 'moment';
import Switch from '@mui/material/Switch';
import { Excel } from 'antd-table-saveas-excel';
import { jsPDF } from 'jspdf';
import autoTable from 'jspdf-autotable';
import Logo from 'assets/orca-logo-black-min.png';
import { SlideDown } from 'react-slidedown';
import 'react-slidedown/lib/slidedown.css';

import dateFns from 'date-fns';

import { Split } from 'styles/common';
import colors from 'styles/colors';

import {
    ReportGeneratorContainer,
    DrivingReportGenerator,
    InputLabel,
    Report,
} from 'styles/reports';

import { OverlayTrigger, Tooltip, Alert } from 'react-bootstrap';

import ReportDataTable from 'containers/ReportDataTableMoveV1';
import GenerateReportHeader from 'containers/ReportGenerator/Header';
import MoveReportModal from '../../../components/Modals/MoveReportModal';
import ReportVehicleSearch from 'components/ReportVehicleSearch';
import { Dashboard } from 'styles/dashboard';
import FormInputTime from 'components/FormInputTime';
import FormInputDate from 'components/FormInputDate';

import Loader from 'components/Loader';

import Error from 'components/Error';
import Chip from 'components/Chip';
import SelectField from 'components/SelectField';

import MagnifyingGlass from 'assets/search-icon-white.svg';
import ReportIcon from 'assets/report-pen.svg';
import Calendar from 'assets/calendar-black.svg';
import Bugs from 'assets/bug.svg';

import {
    validateMoveReportGenerator,
    generateMoveV1Report,
    removeMoveReportError,
    resetMoveV1Report,
    generateIsRouteProPerso,
    setMoveType,
} from 'services/Report/thunks';
import {
    selectMoveReportGenerationErrors,
    selectIsLoading,
    selectMoveV1Report,
    selectMoveModal,
    selectMoveType,
} from 'services/Report/selectors';

import { selectVehicles } from 'services/Vehicle/selectors';
import { selectUnit } from 'services/Units/selectors';
import { isImperial } from 'services/Units/thunks';

import { OUTPUT_FORMATS, REPORT_TYPES, PRESET_PERIODS } from 'services/Report/constants';

import AutoComplete from 'components/AutoComplete';
import ReportsInputDate from '../../../components/ReportsInputDate';
import { array } from 'prop-types';
import Slider from '@mui/material/Slider';
import Typography from '@mui/material/Typography';
const today = dateFns.format(new Date(), 'YYYY-MM-DD');

type Props = {};

type State = {
    equipmentIds: Array<number>,
    periodFrom: string,
    periodTo: string,
    period: string,
    dateLimit: boolean,
    dateLimitPositionLeft: boolean,
    reportGeneratedFilterOpen: boolean,
    format: string,
    type: string,
    street: string,
    placeName: string,
    position: Array<number>,
    periodTimeFrom: string,
    periodTimeTo: string,
    reportType: string,
    distanceRange: Array<number>,
};
let exportData = [];
export class ReportPageComponent extends React.Component<Props, State> {
    reportComponent: React.Component<Props, State>;

    state = {
        equipmentIds: [],
        periodFrom: '',
        periodTo: '',
        dateLimit: false,
        dateLimitPositionLeft: true,
        period: PRESET_PERIODS.custom,
        reportGeneratedFilterOpen: false,
        format: OUTPUT_FORMATS.page,
        type: REPORT_TYPES.detailed,
        street: '',
        placeName: '',
        position: [],
        periodTimeFrom: '',
        periodTimeTo: '',
        reportType: 'Detailed',
        distanceRange: 0,
    };

    componentDidMount = () => {
        this.props.resetMoveV1Report();
        this.setState({ periodFrom: today, periodTo: today });
    };
    componentDidUnMount = () => {
        exportData = [];
    };

    componentDidUpdate = (prevProps) => {
        if (this.props.unit && prevProps.unit !== this.props.unit) {
            this.handleGenerateReport();
        }
    };

    hashCode = (str) => {
        let hash = 0;
        for (let i = 0; i < str.length; i++) {
            hash = str.charCodeAt(i) + ((hash << 5) - hash);
        }
        return hash;
    };
    handleChange = (event, newValue) => {
        this.setState({ distanceRange: newValue });
    };
    intToARGB = (i) => {
        let hex =
            ((i >> 24) & 0xff).toString(16) +
            ((i >> 16) & 0xff).toString(16) +
            ((i >> 8) & 0xff).toString(16) +
            (i & 0xff).toString(16);
        hex += '000000';
        return hex.substring(0, 6);
    };

    convertStringToColor = (str) => {
        if (!str) return '';
        str = str.replace(/\s+/g, '');
        return `#${this.intToARGB(this.hashCode(str))}`;
    };

    hexToHSL = (H) => {
        // Convert hex to RGB first
        let r = 0;

        let g = 0;

        let b = 0;
        if (H.length == 4) {
            r = `0x${H[1]}${H[1]}`;
            g = `0x${H[2]}${H[2]}`;
            b = `0x${H[3]}${H[3]}`;
        } else if (H.length == 7) {
            r = `0x${H[1]}${H[2]}`;
            g = `0x${H[3]}${H[4]}`;
            b = `0x${H[5]}${H[6]}`;
        }
        // Then to HSL
        r /= 100;
        g /= 280;
        b /= 100;
        const cmin = Math.min(r, g, b);

        const cmax = Math.max(r, g, b);

        const delta = cmax - cmin;

        let h = 0;

        if (delta == 0) h = 0;
        else if (cmax == r) h = ((g - b) / delta) % 6;
        else if (cmax == g) h = (b - r) / delta + 2;
        else h = (r - g) / delta + 4;

        h = Math.round(h * 60);

        if (h < 0) h += 360;

        return `hsl(${h},${80}%,${60}%)`;
    };

    handleOnCheckVehicles = (id: number) => {
        const equipmentIds = this.state.equipmentIds.slice();
        if (equipmentIds.indexOf(id) >= 0) {
            equipmentIds.splice(equipmentIds.indexOf(id), 1);
        } else {
            equipmentIds.push(id);
        }
        if (equipmentIds.length > 0 && this.props.errors.get('equipmentIds')) {
            this.props.removeMoveReportError('equipmentIds');
        }
        this.setState({
            equipmentIds,
        });
    };

    handleOnChangePeriod = (key: string) => (event: Event) => {
        this.changePeriod(key, event.target.value);
        this.props.removeMoveReportError(key);
    };

    handleOnChangeGasPrice = (e: Event) => {
        this.setState({
            gasPrice: e.target.value,
        });
    };

    handleGenerateReport = () => {
        this.props.validateMoveReportGenerator(this.state).then(() => {
            //set move report type
            if (this.state.reportType === 'Summary') {
                this.props.setMoveType(true);
            } else {
                this.props.setMoveType(false);
            }
            // submit generation form.
            exportData = [];
            const data = { ...this.state };
            let format = data.format;
            data.format = 'display';
            data.fileName = this.props.intl.formatMessage({
                id: 'views.Reports.SideMenu.Move',
            });
            this.props.generateMoveV1Report(data, this.state.distanceRange).then(() => {
                format === 'pdf'
                    ? this.handleOnExportPDF()
                    : format === 'xlsx'
                        ? this.handleOnExportXLS()
                        : null;
            });
            if (this.state.reportGeneratedFilterOpen) {
                this.setState({
                    reportGeneratedFilterOpen: false,
                });
            }
        });
    };

    changePeriod = (key: string, date: string) => {
        if (this.state.dateLimit) {
            this.setState({
                dateLimit: false,
            });
        }
        if (key === 'periodFrom' && dateFns.differenceInDays(this.state.periodTo, date) > 366) {
            const newDateTo = dateFns.addDays(date, 366);
            const formattedNewDate = dateFns.format(newDateTo, 'YYYY-MM-DD');

            this.setState({
                dateLimit: true,
                periodTo: formattedNewDate,
                dateLimitPositionLeft: true,
            });
        } else if (
            key === 'periodTo' &&
            dateFns.differenceInDays(date, this.state.periodFrom) > 366
        ) {
            const newDateFrom = dateFns.subDays(date, 366);
            const formattedNewDateFrom = dateFns.format(newDateFrom, 'YYYY-MM-DD');
            this.setState({
                dateLimit: true,
                periodFrom: formattedNewDateFrom,
                dateLimitPositionLeft: false,
            });
        }
        let dateToChange = date;
        const dateCompare =
            key == 'periodFrom'
                ? dateFns.compareAsc(date, this.state.periodTo)
                : dateFns.compareAsc(this.state.periodFrom, date);
        const todayCompare = dateFns.compareAsc(date, today);
        if (todayCompare === 1) {
            dateToChange = today;
        }
        if (dateCompare === 1) {
            this.setState(() => ({
                [key == 'periodFrom' ? 'periodTo' : 'periodFrom']: dateToChange,
            }));
        }
        this.setState((prevState) => ({
            [key]: dateToChange,
            period: PRESET_PERIODS.custom,
        }));
    };

    handleSetPeriod = (presetPeriod: string) => () => {
        const currentDate = new Date();
        const period = {};
        this.setState({
            dateLimit: false,
        });
        switch (presetPeriod) {
            case PRESET_PERIODS.today: {
                period.to = dateFns.startOfToday();
                period.from = currentDate;
                break;
            }
            case PRESET_PERIODS.yesterday: {
                const yesterday = dateFns.subDays(currentDate, 1);
                period.from = dateFns.startOfDay(yesterday);
                period.to = dateFns.endOfDay(yesterday);
                break;
            }
            case PRESET_PERIODS.lastWeek: {
                const lastWeek = dateFns.subWeeks(currentDate, 1);
                period.from = dateFns.startOfWeek(lastWeek);
                period.to = dateFns.endOfWeek(lastWeek);
                break;
            }
            case PRESET_PERIODS.lastMonth: {
                const lastMonth = dateFns.subMonths(currentDate, 1);
                period.from = dateFns.startOfMonth(lastMonth);
                period.to = dateFns.endOfMonth(lastMonth);
                break;
            }
            case PRESET_PERIODS.thisMonth: {
                period.from = dateFns.startOfMonth(currentDate);
                period.to = dateFns.endOfMonth(currentDate);
                if (dateFns.compareAsc(today, period.to) < 0) {
                    period.to = today;
                }
                break;
            }
            case PRESET_PERIODS.thisWeek: {
                period.from = dateFns.startOfWeek(currentDate);
                period.to = dateFns.endOfWeek(currentDate);
                if (dateFns.compareAsc(today, period.to) < 0) {
                    period.to = today;
                }
                break;
            }
            default: {
                period.from = '';
                period.to = '';
            }
        }
        if (this.props.errors.get('periodFrom')) {
            this.props.removeMoveReportError('periodFrom');
        }

        if (this.props.errors.get('periodTo')) {
            this.props.removeMoveReportError('periodTo');
        }

        this.setState({
            periodTo: dateFns.format(period.to, 'YYYY-MM-DD'),
            periodFrom: dateFns.format(period.from, 'YYYY-MM-DD'),
            period: presetPeriod,
        });
    };

    handleOnClickModifyReport = () => {
        this.setState((prevState) => ({
            reportGeneratedFilterOpen: !prevState.reportGeneratedFilterOpen,
        }));
        exportData = [];
    };

    hmsToSeconds = (time) => {
        let sec = time.split(' ');
        let hh = 0;
        let mm = 0;
        let ss = 0;
        sec.forEach((el) => {
            if (el.includes('m')) {
                mm = parseInt(el);
            }
            if (el.includes('s')) {
                ss = parseInt(el);
            }
            if (el.includes('h')) {
                hh = parseInt(el);
            }
        });
        return hh * 60 * 60 + mm * 60;
    };

    handleExportReport = (format) => {
        exportData = [];
        const { report } = this.props;
        let vehicleName;
        let grandTotalUtilisation = 0;
        let grandTotalIdle = 0;
        let grandTotalDistance = 0;
        let grandTotalPersonnel = 0;
        let grandTotalProfessionnel = 0;

        if (this.props.moveReportSummary) {
            let distance;
            let idleTime;
            let utilisationTime;
            let distancePerso;
            let distanceWork;
            let horometer;
            let odometer;
            if (format === 'excel') {
                report.get('data').forEach((data) => {
                    data.get('data').forEach((vehData) => {
                        distance = parseFloat(
                            vehData
                                .get('footerdata')
                                .get('totalVehicleDistance')
                                .split(' ')[0]
                        );
                        idleTime = this.hmsToSeconds(
                            vehData.get('footerdata').get('totalVehicleTimeIdle')
                        );
                        utilisationTime = this.hmsToSeconds(
                            vehData.get('footerdata').get('totalVehicleUtilisationTime')
                        );
                        odometer = vehData.get('footerdata').get('lastOdometer');
                        horometer = vehData.get('footerdata').get('lastHorometer');
                        distancePerso = parseFloat(
                            vehData
                                .get('footerdata')
                                .get('totalVehicleDistancePerso')
                                .split(' ')[0]
                        );
                        distanceWork = parseFloat(
                            vehData
                                .get('footerdata')
                                .get('totalVehicleDistanceWork')
                                .split(' ')[0]
                        );
                        exportData.push({
                            type: vehData.get('vehicleNumb'),
                            totalDistance: distance.toFixed(2),
                            utilisationTimeInSec: utilisationTime,
                            totalTimeIdleInSec: idleTime,
                            odometer: odometer,
                            horometer: horometer,
                            totalDistancePerso: distancePerso.toFixed(2),
                            totalDistanceWork: distanceWork.toFixed(2),
                        });
                    });
                    const arrayTotal = Array.from(data.get('total'));
                    console.log('arrayTotal', arrayTotal);
                    exportData.push({
                        type: this.props.intl.formatMessage({
                            id: 'containers.Reports.Move.grandTotal',
                        }),
                        totalDistance: parseFloat(arrayTotal[0][1].split(' ')[0]).toFixed(2),
                        utilisationTimeInSec: this.hmsToSeconds(arrayTotal[1][1]),
                        totalTimeIdleInSec: this.hmsToSeconds(arrayTotal[2][1]),
                        odometer: arrayTotal[7][1],
                        horometer: arrayTotal[6][1],
                        totalDistancePerso: parseFloat(arrayTotal[3][1].split(' ')[0]).toFixed(2),
                        totalDistanceWork: parseFloat(arrayTotal[4][1].split(' ')[0]).toFixed(2),
                    });
                });

                if (this.props.loading) {
                    if (this.props.loading) {
                        return <Loader loading />;
                    }
                }
            } else {
                report.get('data').forEach((data) => {
                    data.get('data').forEach((vehData) => {
                        exportData.push({
                            type: vehData.get('vehicleNumb'),
                            totalDistance: vehData.get('footerdata').get('totalVehicleDistance'),
                            utilisationTimeInSec: vehData
                                .get('footerdata')
                                .get('totalVehicleUtilisationTime'),
                            totalTimeIdleInSec: vehData
                                .get('footerdata')
                                .get('totalVehicleTimeIdle'),
                            odometer: vehData.get('footerdata').get('lastOdometer'),
                            horometer: vehData.get('footerdata').get('lastHorometer'),
                            totalDistancePerso: vehData
                                .get('footerdata')
                                .get('totalVehicleDistancePerso'),
                            totalDistanceWork: vehData
                                .get('footerdata')
                                .get('totalVehicleDistanceWork'),
                        });
                    });
                    const arrayTotal = Array.from(data.get('total'));
                    console.log('arrayTotal 1', arrayTotal);
                    exportData.push({
                        type: this.props.intl.formatMessage({
                            id: 'containers.Reports.Move.grandTotal',
                        }),
                        totalDistance: arrayTotal[0][1],
                        utilisationTimeInSec: arrayTotal[1][1],
                        totalTimeIdleInSec: arrayTotal[2][1],
                        odometer: arrayTotal[7][1],
                        horometer: arrayTotal[6][1],
                        totalDistancePerso: arrayTotal[3][1],
                        totalDistanceWork: arrayTotal[4][1],
                    });
                    console.log('exportData', exportData);
                });

                if (this.props.loading) {
                    if (this.props.loading) {
                        return <Loader loading />;
                    }
                }
            }
        } else {
            let totalAllVehicleGasConsumption = 0;
            let grandTotalTimeUsePersonnel = 0;
            let grandTotalTimeUseProfessionnel = 0;
            let grandTotalTimeIdlePersonnel = 0;
            let grandTotalTimeIdleProfessionnel = 0;
            let typeDeplacement;
            let totalUseTime = 0;
            let totalIdle = 0;
            let grandTotalUtilisation = 0;
            let grandTotalIdle = 0;
            let grandTotalPersonnel = 0;
            let grandTotalProfessionnel = 0;
            let totalTimeIdlePersonnel = 0;
            let totalTimeIdleProfessionnel = 0;
            let totalutilisationTimePersonnel = 0;
            let totalutilisationTimeProfessionnel = 0;
            let hasTotalAllVehicleGasConsumption = false;
            report.get('data').forEach((data) => {
                const arrayData = Array.from(data.get('data'));
                const sortedData = arrayData.sort((a, b) => a[0].localeCompare(b[0]));
                if (data.get('total').get('totalAllVehicleGasConsumption') != '') {
                    hasTotalAllVehicleGasConsumption = true;
                }
                totalAllVehicleGasConsumption = data
                    .get('total')
                    .get('totalAllVehicleGasConsumption');

                sortedData.forEach((data, key) => {
                    console.log('data', data[1].toJS());
                    vehicleName = data[1].get('vehicleNumb');
                    let totalVehicleGasConsumption = false;
                    if (data[1].get('footerdata').get('totalVehicleGasConsumption') !== '0') {
                        totalVehicleGasConsumption = true;
                    }
                    data[1].get('data').forEach((list) => {
                        let hasGasConsumption = false;
                        if (list.get('gasConsumption') !== '0.00') {
                            hasGasConsumption = true;
                        }
                        typeDeplacement = list.get('isPrivateMode')
                            ? this.props.intl.formatMessage({
                                  id: 'containers.Reports.Move.typeDeplacement.Personnal',
                              })
                            : this.props.intl.formatMessage({
                                  id: 'containers.Reports.Move.typeDeplacement.Professional',
                              });
                        if (typeDeplacement === 'Personnel') {
                            totalTimeIdlePersonnel += list.get('totalTimeIdleInSec');
                            totalutilisationTimePersonnel += list.get('utilisationTimeInSec');
                        } else {
                            totalTimeIdleProfessionnel += list.get('totalTimeIdleInSec');
                            totalutilisationTimeProfessionnel += list.get('utilisationTimeInSec');
                        }
                        totalUseTime += list.get('utilisationTimeInSec');
                        totalIdle += list.get('totalTimeIdleInSec');
                        //details
                        exportData.push({
                            type: vehicleName,
                            startTime: list.get('startTime'),
                            endTime: list.get('endTime'),
                            totalDistance: list.get('totalDistance'),
                            utilisationTimeInSec: list.get('utilisationTimeInSec'),
                            totalTimeIdleInSec: list.get('totalTimeIdleInSec'),
                            stopTime: list.get('stopTimeInSecs') ? list.get('stopTimeInSecs') : 0,
                            startAddress: list.get('startAddress'),
                            stopAddress: list.get('stopAddress'),
                            latitudeWgs84: list.get('latitudeWgs84'),
                            longitudeWgs84: list.get('longitudeWgs84'),
                            typeDeplacement: typeDeplacement,
                            horometer: list.get('horometer'),
                            odometer: list.get('odometer'),
                            driverName: list.get('driverName'),
                            geofenceName: list.get('geofenceName'),
                            isPrivateMode: list.get('isPrivateMode'),
                            gasConsumption: hasGasConsumption ? list.get('gasConsumption') : null,
                        });
                    });
                    grandTotalTimeUsePersonnel += totalutilisationTimePersonnel;
                    grandTotalTimeUseProfessionnel += totalutilisationTimeProfessionnel;
                    grandTotalTimeIdlePersonnel += totalTimeIdlePersonnel;
                    grandTotalTimeIdleProfessionnel += totalTimeIdleProfessionnel;
                    grandTotalDistance += parseFloat(
                        data[1].get('footerdata').get('totalVehicleDistance')
                    );
                    grandTotalIdle += totalIdle;
                    (grandTotalPersonnel += parseFloat(
                        data[1].get('footerdata').get('totalVehicleDistancePerso')
                    )),
                        (grandTotalProfessionnel += parseFloat(
                            data[1].get('footerdata').get('totalVehicleDistanceWork')
                        )),
                        (grandTotalUtilisation += totalUseTime);
                    //total
                    exportData.push({
                        type: `${vehicleName} - ${this.props.intl.formatMessage({
                            id: 'containers.Reports.Move.total',
                        })} `,
                        startTime: '',
                        endTime: '',
                        totalDistance: data[1].get('footerdata').get('totalVehicleDistance'),
                        utilisationTimeInSec: totalUseTime,
                        totalTimeIdleInSec: totalIdle,
                        stopTime: '',
                        startAddress: '',
                        stopAddress: '',
                        latitudeWgs84: '',
                        longitudeWgs84: '',
                        typeDeplacement: '',
                        odometer: '',
                        horometer: '',
                        driverName: '0',
                        geofenceName: '',
                        isPrivateMode: '',
                        gasConsumption: totalVehicleGasConsumption
                            ? data[1].get('footerdata').get('totalVehicleGasConsumption')
                            : null,
                    });
                    //total personnel
                    exportData.push({
                        type: `${vehicleName} - ${this.props.intl.formatMessage({
                            id: 'containers.Reports.Move.TotalPersonnel',
                        })}`,
                        startTime: '',
                        endTime: '',
                        totalDistance: data[1].get('footerdata').get('totalVehicleDistancePerso'),
                        utilisationTimeInSec: totalutilisationTimePersonnel,
                        totalTimeIdleInSec: totalTimeIdlePersonnel,
                        stopTime: '',
                        startAddress: '',
                        stopAddress: '',
                        latitudeWgs84: '',
                        longitudeWgs84: '',
                        typeDeplacement: '',
                        odometer: '',
                        horometer: '',
                        driverName: '',
                        geofenceName: '',
                        isPrivateMode: '',
                    });
                    //total professionnel
                    exportData.push({
                        type: `${vehicleName} - ${this.props.intl.formatMessage({
                            id: 'containers.Reports.Move.totalProfessionnel',
                        })} `,
                        startTime: '',
                        endTime: '',
                        totalDistance: data[1].get('footerdata').get('totalVehicleDistanceWork'),
                        utilisationTimeInSec: totalutilisationTimeProfessionnel,
                        totalTimeIdleInSec: totalTimeIdleProfessionnel,
                        stopTime: '',
                        startAddress: '',
                        stopAddress: '',
                        latitudeWgs84: '',
                        longitudeWgs84: '',
                        typeDeplacement: '',
                        odometer: '',
                        horometer: '',
                        driverName: '',
                        geofenceName: '',
                        isPrivateMode: '',
                    });
                    totalutilisationTimePersonnel = 0;
                    totalutilisationTimeProfessionnel = 0;
                    totalTimeIdlePersonnel = 0;
                    totalTimeIdleProfessionnel = 0;
                    totalIdle = 0;
                    totalUseTime = 0;
                });
            });
            exportData.push({
                type: this.props.intl.formatMessage({
                    id: 'containers.Reports.Move.grandTotal',
                }),
                startTime: '',
                endTime: '',
                totalDistance: `${grandTotalDistance.toFixed(2)} ${
                    this.props.isImperial() ? 'mi' : 'km'
                }`,
                utilisationTimeInSec: grandTotalUtilisation,
                totalTimeIdleInSec: grandTotalIdle,
                stopTime: '',
                startAddress: '',
                stopAddress: '',
                latitudeWgs84: '',
                longitudeWgs84: '',
                typeDeplacement: '',
                odometer: '',
                horometer: '',
                driverName: '',
                geofenceName: '',
                isPrivateMode: '',
                gasConsumption: hasTotalAllVehicleGasConsumption
                    ? totalAllVehicleGasConsumption
                    : null,
            });
            exportData.push({
                type: this.props.intl.formatMessage({
                    id: 'containers.Reports.Move.grandTotalPersonnel',
                }),
                startTime: '',
                endTime: '',
                totalDistance: `${grandTotalPersonnel.toFixed(2)} km`,
                utilisationTimeInSec: grandTotalTimeUsePersonnel,
                totalTimeIdleInSec: grandTotalTimeIdlePersonnel,
                stopTime: '',
                startAddress: '',
                stopAddress: '',
                latitudeWgs84: '',
                longitudeWgs84: '',
                typeDeplacement: '',
                odometer: '',
                horometer: '',
                driverName: '',
                geofenceName: '',
                isPrivateMode: '',
            });
            exportData.push({
                type: this.props.intl.formatMessage({
                    id: 'containers.Reports.Move.grandTotalProfessionnel',
                }),
                startTime: '',
                endTime: '',
                totalDistance: `${grandTotalProfessionnel.toFixed(2)} km`,
                utilisationTimeInSec: grandTotalTimeUseProfessionnel,
                totalTimeIdleInSec: grandTotalTimeIdleProfessionnel,
                stopTime: '',
                startAddress: '',
                stopAddress: '',
                latitudeWgs84: '',
                longitudeWgs84: '',
                typeDeplacement: '',
                odometer: '',
                horometer: '',
                driverName: '',
                geofenceName: '',
                isPrivateMode: '',
            });
            if (format === 'pdf') {
                exportData = exportData.map(({ latitudeWgs84, longitudeWgs84, ...rest }) => rest);
            }
            if (this.props.loading) {
                if (this.props.loading) {
                    return <Loader loading />;
                }
            }
        }
    };

    getColumns = (format) => {
        const timeFormatExcel = format == 'excel' ? ' (s)' : '';
        const distanceFormat = this.props.report.getIn(['meta', 'isImperial']) ? '(mi)' : '(km)';
        const distanceformatExcel = format == 'excel' ? ` ${distanceFormat}` : '';
        let columns;
        let columnsSummary;
        if (this.props.moveReportSummary) {
            columnsSummary = [
                {
                    title: '',
                    dataIndex: 'type',
                    key: 'type',
                },
                {
                    title: `${this.props.intl.formatMessage({
                        id: 'containers.Reports.Move.totalDistance',
                    })}${distanceformatExcel}`,
                    dataIndex: 'totalDistance',
                    key: 'totalDistance',
                },
                {
                    title: `${this.props.intl.formatMessage({
                        id: 'containers.Reports.Move.utilisationTimeInSec',
                    })}${timeFormatExcel}`,
                    dataIndex: 'utilisationTimeInSec',
                    key: 'utilisationTimeInSec',
                },
                {
                    title: `${this.props.intl.formatMessage({
                        id: 'containers.Reports.Move.totalTimeIdleInSec',
                    })}${timeFormatExcel}`,
                    dataIndex: 'totalTimeIdleInSec',
                    key: 'totalTimeIdleInSec',
                },
                {
                    title: `${this.props.intl.formatMessage({
                        id: 'containers.Reports.Move.odometer',
                    })}`,
                    dataIndex: 'odometer',
                    key: 'odometer',
                },
                {
                    title: `${this.props.intl.formatMessage({
                        id: 'containers.Reports.Move.horometer',
                    })}`,
                    dataIndex: 'horometer',
                    key: 'horometer',
                },
                {
                    title: `${this.props.intl.formatMessage({
                        id: 'containers.Reports.Move.TotalPersoHeader',
                    })}${distanceformatExcel}`,
                    dataIndex: 'totalDistancePerso',
                    key: 'totalDistancePerso',
                },
                {
                    title: `${this.props.intl.formatMessage({
                        id: 'containers.Reports.Move.TotalProHeader',
                    })}${distanceformatExcel}`,
                    dataIndex: 'totalDistanceWork',
                    key: 'totalDistanceWork',
                },
            ];
        } else {
            const tables = [];
            let hasGasConsumption = false;
            this.props.report.get('data').forEach((data) => {
                data.get('data')
                    .sort()
                    .forEach((data, key) => {
                        tables.push(data.get('data'));
                    });
            });
            if (tables.length > 0) {
                tables.map((item) => {
                    item.toJS().map((vehData) => {
                        if (vehData.gasConsumption !== 0) {
                            hasGasConsumption = true;
                        }
                    });
                });
            }

            columns = [
                {
                    title: '',
                    dataIndex: 'type',
                    key: 'type',
                },
                {
                    title: this.props.intl.formatMessage({
                        id: 'containers.Reports.Move.startTime',
                    }),
                    dataIndex: 'startTime',
                    key: 'startTime',
                },
                {
                    title: this.props.intl.formatMessage({
                        id: 'containers.Reports.Move.endTime',
                    }),
                    dataIndex: 'endTime',
                    key: 'endTime',
                },
                {
                    title: `${this.props.intl.formatMessage({
                        id: 'containers.Reports.Move.totalDistance',
                    })}${distanceformatExcel}`,
                    dataIndex: 'totalDistance',
                    key: 'totalDistance',
                },
                {
                    title: `${this.props.intl.formatMessage({
                        id: 'containers.Reports.Move.utilisationTimeInSec',
                    })}${timeFormatExcel}`,
                    dataIndex: 'utilisationTimeInSec',
                    key: 'utilisationTimeInSec',
                },
                {
                    title: `${this.props.intl.formatMessage({
                        id: 'containers.Reports.Move.totalTimeIdleInSec',
                    })}${timeFormatExcel}`,
                    dataIndex: 'totalTimeIdleInSec',
                    key: 'totalTimeIdleInSec',
                },
                {
                    title: `${this.props.intl.formatMessage({
                        id: 'containers.Reports.Move.stopTime',
                    })}${timeFormatExcel}`,
                    dataIndex: 'stopTime',
                    key: 'stopTime',
                },
                {
                    title: this.props.intl.formatMessage({
                        id: 'containers.Reports.Move.startAddress',
                    }),
                    dataIndex: 'startAddress',
                    key: 'startAddress',
                },
                {
                    title: this.props.intl.formatMessage({
                        id: 'containers.Reports.Move.stopAddress',
                    }),
                    dataIndex: 'stopAddress',
                    key: 'stopAddress',
                },
                {
                    title: this.props.intl.formatMessage({
                        id: 'containers.Reports.Move.latitudeWgs84',
                    }),
                    dataIndex: 'latitudeWgs84',
                    key: 'latitudeWgs84',
                },
                {
                    title: this.props.intl.formatMessage({
                        id: 'containers.Reports.Move.longitudeWgs84',
                    }),
                    dataIndex: 'longitudeWgs84',
                    key: 'longitudeWgs84',
                },
                {
                    title: this.props.intl.formatMessage({
                        id: 'containers.Reports.Move.typeDeplacement',
                    }),
                    dataIndex: 'typeDeplacement',
                    key: 'typeDeplacement',
                },
                {
                    title: this.props.intl.formatMessage({
                        id: 'containers.Reports.Move.odometer',
                    }),
                    dataIndex: 'odometer',
                    key: 'odometer',
                },
                {
                    title: this.props.intl.formatMessage({
                        id: 'containers.Reports.Move.horometer',
                    }),
                    dataIndex: 'horometer',
                    key: 'horometer',
                },
                {
                    title: this.props.intl.formatMessage({
                        id: 'containers.Reports.Move.driverName',
                    }),
                    dataIndex: 'driverName',
                    key: 'driverName',
                },
                {
                    title: this.props.intl.formatMessage({
                        id: 'containers.Reports.Move.geofence',
                    }),
                    dataIndex: 'geofenceName',
                    key: 'geofenceName',
                },
            ];
            if (format === 'pdf') {
                columns = columns.filter(
                    (column) => column.key !== 'latitudeWgs84' && column.key !== 'longitudeWgs84'
                );
            }
            if (hasGasConsumption) {
                columns.push({
                    title: this.props.intl.formatMessage({
                        id: 'containers.Reports.Generators.move.Fuel',
                    }),
                    dataIndex: 'gasConsumption',
                    key: 'gasConsumption',
                });
            }
        }
        const result = this.props.moveReportSummary ? columnsSummary : columns;
        return result;
    };
    getHead = () => {
        const headPdf = [
            {
                title: this.props.intl.formatMessage({
                    id: 'containers.Reports.Move.type',
                }),
            },
            {
                title: this.props.intl.formatMessage({
                    id: 'containers.Reports.Move.vehicleName',
                }),
            },
            {
                title: this.props.intl.formatMessage({
                    id: 'containers.Reports.Move.startTime',
                }),
            },
            {
                title: this.props.intl.formatMessage({
                    id: 'containers.Reports.Move.endTime',
                }),
            },
            {
                title: this.props.intl.formatMessage({
                    id: 'containers.Reports.Move.totalDistance',
                }),
            },
            {
                title: this.props.intl.formatMessage({
                    id: 'containers.Reports.Move.utilisationTimeInSec',
                }),
            },

            /*         {
                        title: this.props.intl.formatMessage({
                            id: 'containers.Reports.Move.carburant',
                        })
                    }, */
            {
                title: this.props.intl.formatMessage({
                    id: 'containers.Reports.Move.startAddress',
                }),
            },
            {
                title: this.props.intl.formatMessage({
                    id: 'containers.Reports.Move.stopAddress',
                }),
            },
            {
                title: this.props.intl.formatMessage({
                    id: 'containers.Reports.Move.typeDeplacement',
                }),
            },
            {
                title: this.props.intl.formatMessage({
                    id: 'containers.Reports.Move.odometer',
                }),
            },
            {
                title: this.props.intl.formatMessage({
                    id: 'containers.Reports.Move.horometer',
                }),
            },
            {
                title: this.props.intl.formatMessage({
                    id: 'containers.Reports.Move.driverName',
                }),
            },
            {
                title: this.props.intl.formatMessage({
                    id: 'containers.Reports.Move.geofence',
                }),
            },
        ];
        return headPdf;
    };

    formatSecondsToHMS = (seconds) => {
        if (seconds == '') {
            return '0s';
        }
        const hours = Math.floor(seconds / 3600);
        const remainingMinutes = Math.floor((seconds % 3600) / 60);
        const remainingSeconds = seconds % 60;

        if (hours === 0 && remainingMinutes === 0 && remainingSeconds === 0) {
            return '';
        }
        let formattedTime = '';

        if (hours > 0) {
            formattedTime += `${hours}h `;
        }
        if (remainingMinutes > 0) {
            formattedTime += `${remainingMinutes}m `;
        }
        if (remainingSeconds > 0 || formattedTime === '') {
            formattedTime += `${remainingSeconds}s`;
        }
        if (formattedTime.split(' ')[1]) {
            return `${formattedTime.split(' ')[0]} ${formattedTime.split(' ')[1]} `;
        }
        return formattedTime;
    };

    handleOnExportPDF = () => {
        const generateAt = this.props.intl
            .formatMessage({ id: 'components.Reports.move.pdf.generate' })
            .toString();
        const doc = new jsPDF('l');
        this.handleExportReport('pdf');
        let pdf = exportData.map((data) => {
            if (!this.props.moveReportSummary) {
                if (data.horometer > 0) {
                    data.horometer = data.horometer;
                }
                if (data.odometer > 0) {
                    data.odometer = data.odometer;
                }
                data.utilisationTimeInSec = this.formatSecondsToHMS(data.utilisationTimeInSec);
                data.totalTimeIdleInSec = this.formatSecondsToHMS(data.totalTimeIdleInSec);
                if (data.startTime != '') {
                    data.stopTime = this.formatSecondsToHMS(data.stopTime);
                }
            }
            return Object.values(data);
        });
        doc.setFontSize(8);
        doc.text(generateAt + new moment(new Date()).format('YYYY-MM-DD HH:mm:ss'), 150, 10);
        doc.addImage(Logo, 'png', 2, 2);
        autoTable(doc, {
            headStyles: { fillColor: colors.green63, fontSize: '7' },
            head: [this.getColumns('pdf')],

            bodyStyles: { fontSize: '7' },
            body: pdf,
        });

        doc.save(
            `${this.props.intl.formatMessage({
                id: 'views.Reports.SideMenu.Move',
            })}.pdf`
        );
    };
    formatOdometerMeterstoKm = (meters) => {
        if (meters === '') {
            return '0 km';
        }
        if (this.props.isImperial()) {
            const miles = meters / 1609.34;
            return `${miles.toFixed(2)} mi`;
        } else {
            const kilometers = meters / 1000;
            return `${kilometers.toFixed(2)} km`;
        }
    };
    handleOnExportXLS = () => {
        this.handleExportReport('excel');
        let excelData = exportData;
        if (!this.props.moveReportSummary) {
            excelData = exportData.map((data) => {
                data.totalDistance = data.totalDistance.split(' ')[0];
                return data;
            });
        }
        const excel = new Excel();
        excel
            .addSheet('MoveReport')
            .addColumns(this.getColumns('excel'))
            .addDataSource(excelData)
            .saveAs(
                `${this.props.intl.formatMessage({
                    id: 'views.Reports.SideMenu.Move',
                })}.xlsx`
            );
    };

    handleOnSelectAllVehicles = (searchedEquipments) => {
        let equipmentIds;
        if (searchedEquipments.toArray().length > 0) {
            equipmentIds = searchedEquipments.map((equipment) => equipment.get('id')).toArray();
        } else {
            equipmentIds = this.props.equipment
                .filter((eq) => {
                    if (eq.toJS().cardNumber) {
                        return !eq.toJS().cardNumber.startsWith('B');
                    } else {
                        return true;
                    }
                })
                .map((equipment) => equipment.get('id'))
                .toArray();
        }
        this.setState({
            equipmentIds,
        });
    };

    handleOnUnselectAllVehicles = () => {
        const equipmentIds = [];
        this.setState({
            equipmentIds,
        });
    };

    onChangeSelect = (key: string) => (value: string) => {
        this.setState({ [key]: value });
    };

    handleAddress = (place) => {
        if (place !== null) {
            this.setState({
                placeName: place.place_name,
                street: place.text,
                position: place.geometry.coordinates,
            });
        }
    };

    handleClearAddress = () => {
        this.setState({
            placeName: '',
            street: '',
            position: [],
        });
    };

    /**
     *
     */
    handleOnChangePeriodFrom = (field) => (event) => {
        const time = event.target.value;
        const timeCompare = dateFns.compareAsc(this.state.periodTo, this.state.periodFrom);
        if (field === 'periodTimeFrom') {
            if (timeCompare === 0) {
                if (time > this.state.periodTimeTo) {
                    this.setState({
                        periodTimeTo: time,
                    });
                }
            }
        } else {
            if (timeCompare === 0) {
                if (time < this.state.periodTimeFrom) {
                    this.setState({
                        periodTimeFrom: time,
                    });
                }
            }
        }
        this.setState({
            [field]: time,
        });
    };

    // To be refactored...
    renderGenerator = () => {
        if (this.props.loading) {
            if (this.props.loading) {
                return <Loader loading />;
            }
        }
        const { distanceRange } = this.state;
        return (
            <div>
                <DrivingReportGenerator>
                    <div className={'economieReport'}>
                        <ReportsInputDate
                            startDate={this.state.periodFrom}
                            onChangeStartDate={this.handleOnChangePeriod('periodFrom')}
                            errorsStartDate={this.props.errors.get('periodFrom')}
                            endDate={this.state.periodTo}
                            onChangeEndDate={this.handleOnChangePeriod('periodTo')}
                            errorEndDate={this.props.errors.get('periodTo')}
                            dateLimit={this.state.dateLimit}
                            dateLimitPositionLeft={this.state.dateLimitPositionLeft}
                        />

                        <DrivingReportGenerator.ColumnRep>
                            <InputLabel margin={'0 0 15px 0'}>
                                <FormattedMessage
                                    id={'containers.Reports.Generators.Driving.OutputFormat'}
                                />
                            </InputLabel>

                            <DrivingReportGenerator.SelectContainer>
                                <SelectField
                                    options={[
                                        {
                                            label: this.props.intl.formatMessage({
                                                id:
                                                    'containers.Reports.Generators.Driving.OutputFormat.Page',
                                            }),
                                            value: OUTPUT_FORMATS.page,
                                        },
                                        {
                                            label: this.props.intl.formatMessage({
                                                id:
                                                    'containers.Reports.Generators.Driving.OutputFormat.Pdf',
                                            }),
                                            value: OUTPUT_FORMATS.pdf,
                                        },
                                        {
                                            label: this.props.intl.formatMessage({
                                                id:
                                                    'containers.Reports.Generators.Driving.OutputFormat.Xls',
                                            }),
                                            value: OUTPUT_FORMATS.xls,
                                        },
                                    ]}
                                    value={this.state.format}
                                    borderColor={colors.grayED}
                                    arrowColor={colors.green63}
                                    onChange={this.onChangeSelect('format')}
                                />
                            </DrivingReportGenerator.SelectContainer>
                        </DrivingReportGenerator.ColumnRep>
                        <div style={{ marginLeft: '27px' }}>
                            <DrivingReportGenerator.ColumnRep>
                                <InputLabel margin={'0 0 15px 0'}>Type</InputLabel>
                                <DrivingReportGenerator.SelectContainer>
                                    <SelectField
                                        options={[
                                            {
                                                label: this.props.intl.formatMessage({
                                                    id:
                                                        'containers.Reports.Generators.Driving.MoveReportType.Detailed',
                                                }),
                                                value: 'Detailed',
                                            },
                                            {
                                                label: this.props.intl.formatMessage({
                                                    id:
                                                        'containers.Reports.Generators.Driving.MoveReportType.Summary',
                                                }),
                                                value: 'Summary',
                                            },
                                        ]}
                                        value={this.state.reportType}
                                        borderColor={colors.grayED}
                                        arrowColor={colors.green63}
                                        onChange={this.onChangeSelect('reportType')}
                                    />
                                </DrivingReportGenerator.SelectContainer>
                            </DrivingReportGenerator.ColumnRep>
                        </div>
                    </div>

                    <div>
                        <DrivingReportGenerator.ColumnRep>
                            <DrivingReportGenerator.ButtonContainer>
                                <DrivingReportGenerator.DateButton
                                    onClick={this.handleSetPeriod(PRESET_PERIODS.today)}
                                >
                                    <FormattedMessage
                                        id={'containers.Reports.Generators.Driving.Today'}
                                    />
                                </DrivingReportGenerator.DateButton>
                                <DrivingReportGenerator.DateButton
                                    onClick={this.handleSetPeriod(PRESET_PERIODS.yesterday)}
                                >
                                    <FormattedMessage
                                        id={'containers.Reports.Generators.Driving.Yesterday'}
                                    />
                                </DrivingReportGenerator.DateButton>
                                <DrivingReportGenerator.DateButton
                                    onClick={this.handleSetPeriod(PRESET_PERIODS.thisWeek)}
                                >
                                    <FormattedMessage
                                        id={'containers.Reports.Generators.Driving.ThisWeek'}
                                    />
                                </DrivingReportGenerator.DateButton>
                                <DrivingReportGenerator.DateButton
                                    onClick={this.handleSetPeriod(PRESET_PERIODS.lastWeek)}
                                >
                                    <FormattedMessage
                                        id={'containers.Reports.Generators.Driving.LastWeek'}
                                    />
                                </DrivingReportGenerator.DateButton>
                                <DrivingReportGenerator.DateButton
                                    onClick={this.handleSetPeriod(PRESET_PERIODS.thisMonth)}
                                >
                                    <FormattedMessage
                                        id={'containers.Reports.Generators.Driving.ThisMonth'}
                                    />
                                </DrivingReportGenerator.DateButton>
                                <DrivingReportGenerator.DateButton
                                    onClick={this.handleSetPeriod(PRESET_PERIODS.lastMonth)}
                                >
                                    <FormattedMessage
                                        id={'containers.Reports.Generators.Driving.LastMonth'}
                                    />
                                </DrivingReportGenerator.DateButton>
                                <DrivingReportGenerator.SubmitButton
                                    onClick={this.handleGenerateReport}
                                >
                                    <FormattedMessage
                                        id={'containers.Reports.Generators.Driving.Generate'}
                                    />
                                </DrivingReportGenerator.SubmitButton>
                            </DrivingReportGenerator.ButtonContainer>
                        </DrivingReportGenerator.ColumnRep>
                    </div>

                    <div className={'MoveV1Report'}>
                        <DrivingReportGenerator.ColumnRep>
                            <OverlayTrigger
                                key={'right'}
                                placement={'right'}
                                overlay={
                                    <Tooltip id="tooltip-right">
                                        <FormattedMessage
                                            id={
                                                'containers.Reports.Generators.Driving.timeshift.info'
                                            }
                                        />
                                    </Tooltip>
                                }
                            >
                                <InputLabel margin={'0 0 15px 0'}>
                                    <FormattedMessage
                                        id={'containers.Reports.Generators.Driving.timeshift'}
                                    />{' '}
                                    <i className="fas fa-info-circle" />{' '}
                                    <FormattedMessage
                                        id={'containers.Reports.Generators.Driving.From'}
                                    />
                                </InputLabel>
                            </OverlayTrigger>
                            <FormInputTime
                                type={'time'}
                                value={this.state.periodTimeFrom}
                                onChange={this.handleOnChangePeriodFrom('periodTimeFrom')}
                                error={this.props.errors.get('periodTimeFrom')}
                                placeholderId={'views.MapPage.HistoryOptions.selectHour'}
                            />
                        </DrivingReportGenerator.ColumnRep>
                        <DrivingReportGenerator.ColumnRep>
                            <InputLabel margin={'0 0 15px 0'}>
                                <FormattedMessage id={'containers.Reports.Generators.Driving.To'} />
                            </InputLabel>
                            <FormInputTime
                                type={'time'}
                                onChange={this.handleOnChangePeriodFrom('periodTimeTo')}
                                error={this.props.errors.get('periodTimeTo')}
                                value={this.state.periodTimeTo}
                                placeholderId={'views.MapPage.HistoryOptions.selectHour'}
                            />
                        </DrivingReportGenerator.ColumnRep>
                    </div>
                    <DrivingReportGenerator.ColumnRep>
                        <OverlayTrigger
                            key={'right'}
                            placement={'right'}
                            overlay={
                                <Tooltip id="tooltip-right">
                                    <FormattedMessage id={'components.AutoComplete.text.info'} />
                                </Tooltip>
                            }
                        >
                            <InputLabel margin={'0 0 15px 0'} style={{ maxWidth: 100 }}>
                                <FormattedMessage
                                    id={'containers.Reports.Generators.SearchAddress'}
                                />{' '}
                                <i className="fas fa-info-circle" />
                            </InputLabel>
                        </OverlayTrigger>
                        <AutoComplete
                            placeholder={this.state.placeName}
                            onSelect={(place) => this.handleAddress(place)}
                            onClear={() => this.handleClearAddress()}
                        />
                    </DrivingReportGenerator.ColumnRep>

                    <InputLabel margin={'0 0 15px 0'} style={{ maxWidth: 300 }}>
                        <FormattedMessage id={'containers.Reports.Generators.distanceRange'} />{' '}
                    </InputLabel>
                    <div style={{ width: 300 }}>
                        <Slider
                            value={distanceRange}
                            onChange={this.handleChange}
                            valueLabelDisplay="auto"
                            min={0}
                            max={5}
                            step={1}
                            marks={[
                                { value: 0, label: '0 km' },
                                { value: 1, label: '1 km' },
                                { value: 2, label: '2 km' },
                                { value: 3, label: '3 km' },
                                { value: 4, label: '4 km' },
                                { value: 5, label: '5 km' },
                            ]}
                            sx={{
                                color: '#63c132',
                                '& .MuiSlider-thumb': {
                                    height: 16,
                                    width: 16,
                                    backgroundColor: '#63c132',
                                    border: '2px solid currentColor',
                                },
                                '& .MuiSlider-track': {
                                    height: 6,
                                },
                                '& .MuiSlider-rail': {
                                    height: 6,
                                    backgroundColor: '#d3d3d3',
                                },
                            }}
                        />
                    </div>
                </DrivingReportGenerator>
                <Split color={colors.splitGrayReports} opacity={0.2} />
                <InputLabel margin={'30px 0 20px 0'}>
                    <FormattedMessage id={'containers.Reports.Generators.Driving.Vehicles'} />
                </InputLabel>
                {this.props.errors.get('equipmentIds') && (
                    <DrivingReportGenerator.Error>
                        <Error
                            text={this.props.intl.formatMessage({
                                id: 'containers.Reports.Generators.Driving.EquipmentIdsError',
                            })}
                        />
                    </DrivingReportGenerator.Error>
                )}
                <ReportVehicleSearch
                    equipment={this.props.equipment.filter((eq) => {
                        if (eq.toJS().cardNumber) {
                            return !eq.toJS().cardNumber.startsWith('B');
                        } else {
                            return true;
                        }
                    })}
                    onCheckVehicles={this.handleOnCheckVehicles}
                    checkedInputs={this.state.equipmentIds}
                    onSelectAllVehicles={this.handleOnSelectAllVehicles}
                    onUnselectAllVehicles={this.handleOnUnselectAllVehicles}
                />
                <Split color={colors.splitGrayReports} opacity={0.2} />
            </div>
        );
    };

    /**
     * Render that shows
     * @param {number} columnSize Decides on how many columns
     * @param {boolean} showExtraColumns show or not total stop time
     * @param {boolean} tableFooter get totalVehicleUtilisationTime && totalVehicleDistance
     * @return {Component} rendered component
     */
    renderFooterAllVehiclesItem = (getTableSize, tableFooter, driversDataFooter) => {
        let hasAllTotalGasConsumption = false;

        let columnSize = 7;
        const { report } = this.props;
        const reportFilters = report.getIn(['meta', 'filters']);
        const reportFrom = dateFns.format(reportFilters.get('datetimeStart'), 'YYYY/MM/DD');
        const reportTo = dateFns.format(reportFilters.get('datetimeEnd'), 'YYYY/MM/DD');
        const driverFooterItems = [];
        if (tableFooter.get('totalAllVehicleGasConsumption') !== '0.00') {
            hasAllTotalGasConsumption = true;
            columnSize = 6;
        }
        driversDataFooter &&
            driversDataFooter.sort().forEach((s, index) => {
                driverFooterItems.push(
                    <Dashboard.FooterContainer columns={columnSize}>
                        <Dashboard.FooterItem>
                            <Chip
                                type="driver"
                                color={this.hexToHSL(
                                    this.convertStringToColor(s.get('data').get('driver'))
                                )}
                            >
                                <i className="far fa-id-badge" />
                                &nbsp;
                                {s.get('data').get('driver')}
                            </Chip>
                        </Dashboard.FooterItem>
                        <Dashboard.FooterItem>
                            <empty />
                        </Dashboard.FooterItem>
                        <Dashboard.FooterItem>
                            <empty />
                        </Dashboard.FooterItem>

                        <Dashboard.FooterItem>
                            <Chip type="personal">
                                {`${Number(s.get('data').get('distancePerso')).toFixed(0)} ${
                                    this.props.isImperial() ? 'mi' : 'km'
                                }`}
                            </Chip>
                            <Chip type="work">
                                {`${Number(s.get('data').get('distanceWork')).toFixed(0)} ${
                                    this.props.isImperial() ? 'mi' : 'km'
                                }`}
                            </Chip>
                        </Dashboard.FooterItem>
                        <Dashboard.FooterItem>
                            <empty />
                        </Dashboard.FooterItem>
                    </Dashboard.FooterContainer>
                );
            });

        return getTableSize <= 1 && driverFooterItems.length == 0 ? (
            <div
                style={{
                    backgroundColor: 'rgba(255, 0, 0, 0.075)',
                    padding: '10px',
                    width: '50%',
                    margin: '0 auto',
                }}
            >
                <empty />
                <center>
                    <Report.Icon src={Bugs} width={100} height={100} margin={'0 9px 0 0'} />
                    <h3>
                        {' '}
                        <FormattedMessage id={'Views.TableVide.Travels'} />
                    </h3>
                </center>
            </div>
        ) : (
            <Alert
                style={{
                    color: colors.black,
                    backgroundColor: '#bfbfbf33',
                    borderColor: colors.green63,
                }}
            >
                {getTableSize > 1 ? (
                    <Dashboard.FooterContainer columns={columnSize}>
                        <Dashboard.FooterItem>
                            <Dashboard.FooterText>
                                <FormattedMessage id={'containers.Reports.Generators.AllTotal'} />{' '}
                                {reportFrom}{' '}
                                <FormattedMessage id={'containers.Reports.Generators.To'} />{' '}
                                {reportTo}
                            </Dashboard.FooterText>
                        </Dashboard.FooterItem>
                        <Dashboard.FooterItem>
                            <Dashboard.FooterText>
                                {tableFooter.get('totalAllVehicleUtilisationTime')}
                            </Dashboard.FooterText>
                        </Dashboard.FooterItem>
                        <Dashboard.FooterItem>
                            <Dashboard.FooterText>
                                {tableFooter.get('totalAllVehicleTimeIdle')}
                            </Dashboard.FooterText>
                        </Dashboard.FooterItem>
                        <Dashboard.FooterItem>
                            <Chip type="distance">
                                {tableFooter.get('totalAllVehicleDistance')}
                            </Chip>
                        </Dashboard.FooterItem>
                        <Dashboard.FooterItem>
                            <Chip type="personal">
                                {`${tableFooter.get('totalAllVehicleDistancePerso')}`}
                            </Chip>
                            <Chip type="work">
                                {`${tableFooter.get('totalAllVehicleDistanceWork')}`}
                            </Chip>
                        </Dashboard.FooterItem>
                        {hasAllTotalGasConsumption ? (
                            <Dashboard.FooterItem>
                                <Dashboard.FooterText>
                                    {tableFooter.get('totalAllVehicleGasConsumption')}
                                </Dashboard.FooterText>
                            </Dashboard.FooterItem>
                        ) : null}
                        <Dashboard.FooterItem>
                            <Dashboard.FooterText />
                        </Dashboard.FooterItem>
                    </Dashboard.FooterContainer>
                ) : (
                    <empty />
                )}
                {driverFooterItems.length > 0
                    ? [
                          getTableSize > 1 ? (
                              <hr style={{ borderTopColor: colors.green63 }} />
                          ) : (
                              <empty />
                          ),
                          <Dashboard.FooterContainer columns={columnSize}>
                              <Dashboard.FooterItem>
                                  <Dashboard.FooterText>
                                      <FormattedMessage id={'views.Reports.Move.details.driver'} />
                                  </Dashboard.FooterText>
                              </Dashboard.FooterItem>
                          </Dashboard.FooterContainer>,
                          driverFooterItems,
                      ]
                    : null}
            </Alert>
        );
    };

    renderReport = () => {
        const reportTitle = this.props.intl.formatMessage({
            id: 'containers.Reports.Generators.MoveV1Title',
        });
        const { report } = this.props;

        const entries = [
            {
                key: 'startTime',
                label: this.props.intl.formatMessage({
                    id: 'containers.Reports.Generators.MoveV1Headers.startTime',
                }),
                sortMethod: null,
            },
            {
                key: 'endTime',
                label: this.props.intl.formatMessage({
                    id: 'containers.Reports.Generators.MoveV1Headers.endTime',
                }),
                sortMethod: null,
            },
            {
                key: 'utilisationTime',
                label: this.props.intl.formatMessage({
                    id: 'containers.Reports.Generators.MoveV1Headers.utilisationTime',
                }),
                sortMethod: null,
            },
            {
                key: 'stopTime',
                label: this.props.intl.formatMessage({
                    id: 'containers.Reports.Generators.MoveV1Headers.stopTime',
                }),
                sortMethod: null,
            },
            {
                key: 'totalTimeIdle',
                label: this.props.intl.formatMessage({
                    id: 'containers.Reports.Generators.MoveV1Headers.totalTimeIdle',
                }),
                sortMethod: null,
            },
            {
                key: 'totalDistance',
                label: this.props.intl.formatMessage({
                    id: 'containers.Reports.Generators.MoveV1Headers.totalDistance',
                }),
                sortMethod: null,
            },
            {
                key: 'driverName',
                label: this.props.intl.formatMessage({
                    id: 'containers.Reports.Generators.MoveV1Headers.driverName',
                }),
                sortMethod: null,
            },
            {
                key: 'stopAddress',
                label: this.props.intl.formatMessage({
                    id: 'containers.Reports.Generators.MoveV1Headers.stopAddress',
                }),
                sortMethod: null,
            },
            {
                key: 'isPrivateMode',
                label: this.props.intl.formatMessage({
                    id: 'containers.Reports.Move.typeDeplacement',
                }),
                sortMethod: null,
            },
            {
                key: 'odometeer',
                label: this.props.intl.formatMessage({
                    id: 'containers.Reports.Generators.MoveV1Headers.odometer',
                }),
                sortMethod: null,
            },
            {
                key: 'horometer',
                label: this.props.intl.formatMessage({
                    id: 'containers.Reports.Generators.MoveV1Headers.horometer',
                }),
                sortMethod: null,
            },
            {
                key: 'geofenceName',
                label: this.props.intl.formatMessage({
                    id: 'containers.Reports.Generators.MoveV1Headers.geofenceName',
                }),
                sortMethod: null,
            },
            {
                key: 'transitTime',
                label: this.props.intl.formatMessage({
                    id: 'containers.Reports.Generators.MoveV1Headers.transitTime',
                }),
                sortMethod: null,
            },
            {
                key: 'distanceTarget',
                label: this.props.intl.formatMessage({
                    id: 'containers.Reports.Generators.MoveV1Headers.distanceTarget',
                }),
                sortMethod: null,
            },
            {
                key: 'gasConsumption',
                label: this.props.intl.formatMessage({
                    id: 'containers.Reports.Generators.MoveV1Headers.gasConsumption',
                }),
                sortMethod: null,
            },
            {
                key: 'startAddress',
                label: this.props.intl.formatMessage({
                    id: 'containers.Reports.Generators.MoveV1Headers.startAddress',
                }),
                sortMethod: null,
            },
        ];

        const reportFilters = report.getIn(['meta', 'filters']);

        const reportFilterText = [];
        const reportFrom = dateFns.format(reportFilters.get('datetimeStart'), 'YYYY/MM/DD');
        const reportTo = dateFns.format(reportFilters.get('datetimeEnd'), 'YYYY/MM/DD');

        reportFilterText.date = `${this.props.intl.formatMessage({
            id: 'containers.Reports.Generators.From',
        })} ${reportFrom} ${this.props.intl.formatMessage({
            id: 'containers.Reports.Generators.To',
        })} ${reportTo}`;
        reportFilterText.time =
            periodetimeStart && periodetimeEnd
                ? `${this.props.intl.formatMessage({
                      id: 'containers.Reports.Generators.periode',
                  })} ${periodetimeStart} - ${periodetimeEnd}`
                : '';
        const periodetimeStart = reportFilters.get('periodetimeStart');
        const periodetimeEnd = reportFilters.get('periodetimeEnd');

        const tables = [];

        let getTableSize;

        report.get('data').forEach((data) => {
            if (data.get('data')) {
                const arrayData = Array.from(data.get('data'));
                const sortedData = arrayData.sort((a, b) => a[0].localeCompare(b[0]));
                sortedData.forEach((data, key) => {
                    tables.push(
                        <ReportDataTable
                            title={data[1].get('vehicleNumb')}
                            vehicleId={data[1].get('vehicleId')}
                            columns={entries}
                            tableData={data[1].get('data')}
                            tableFooter={data[1].get('footerdata')}
                            reportFilterText={reportFilterText}
                        />
                    );
                });
            }
            getTableSize = tables.length;
        });

        if (this.props.loading) {
            if (this.props.loading) {
                return <Loader loading />;
            }
        }

        return (
            <Report.Header>
                <DrivingReportGenerator.ReportType style={{ marginTop: 15, marginBottom: 40 }}>
                    {!this.state.reportGeneratedFilterOpen && (
                        <React.Fragment>
                            <Report.TextContainer>
                                <Report.Icon
                                    src={ReportIcon}
                                    width={26}
                                    height={26}
                                    margin={'0 9px 0 0'}
                                />
                                {reportTitle}
                            </Report.TextContainer>
                            <Report.TextContainer>
                                <Report.Icon
                                    src={Calendar}
                                    width={26}
                                    height={26}
                                    margin={'0 9px 0 0'}
                                />
                                {reportFilterText.date}
                                <br />
                                {reportFilterText.time}
                            </Report.TextContainer>
                        </React.Fragment>
                    )}
                    <Report.ModifyReport onClick={this.handleOnClickModifyReport}>
                        <Report.Icon
                            src={MagnifyingGlass}
                            width={20}
                            height={20}
                            margin={'0 0 0 17px'}
                        />
                        <p style={{ marginTop: '0px', marginBottom: '0px' }}>
                            <FormattedMessage id={'containers.Reports.Generators.Modify'} />
                        </p>
                    </Report.ModifyReport>
                </DrivingReportGenerator.ReportType>
                <SlideDown className={'generator'}>
                    {this.state.reportGeneratedFilterOpen ? this.renderGenerator() : null}
                </SlideDown>
                {tables}
                {this.renderFooterAllVehiclesItem(
                    getTableSize,
                    report.getIn(['data', 'moveSummary', 'total']),
                    report.getIn(['data', 'moveSummary', 'driverdata'])
                )}
            </Report.Header>
        );
    };

    render() {
        return (
            <ReportGeneratorContainer>
                {this.props.moveReportModal ? <MoveReportModal /> : ''}
                <GenerateReportHeader
                    report={this.props.report}
                    title={this.props.intl.formatMessage({
                        id: 'views.Reports.SideMenu.Move',
                    })}
                    onExportPDF={
                        !this.state.reportGeneratedFilterOpen ? this.handleOnExportPDF : null
                    }
                    onExportXLS={
                        !this.state.reportGeneratedFilterOpen ? this.handleOnExportXLS : null
                    }
                />
                <Split color={colors.splitGrayReports} opacity={0.2} margin={'0 0 28px 0'} />
                {this.props.report && this.props.report.size > 0
                    ? this.renderReport()
                    : this.renderGenerator()}
            </ReportGeneratorContainer>
        );
    }
}

const mapStateToProps: Object = createStructuredSelector({
    errors: selectMoveReportGenerationErrors(),
    loading: selectIsLoading(),
    report: selectMoveV1Report(),
    equipment: selectVehicles(),
    unit: selectUnit(),
    moveReportModal: selectMoveModal(),
    moveReportSummary: selectMoveType(),
});

const mapDispatchToProps: Object = (dispatch) =>
    bindActionCreators(
        {
            validateMoveReportGenerator,
            generateMoveV1Report,
            removeMoveReportError,
            isImperial,
            resetMoveV1Report,
            generateIsRouteProPerso,
            setMoveType,
        },
        dispatch
    );

export default withRouter(
    connect(
        mapStateToProps,
        mapDispatchToProps
    )(injectIntl(ReportPageComponent))
);
